import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { useParams } from "@reach/router";
import { useQueryParam } from "gatsby-query-params";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import { getTransaction, sendDonerName } from "../../api/churches";
import Button from "../Shared/Button";

import s from "./ReceiptPage.module.css";

const ReceiptPage = () => {
  const [receipt, setReceipt] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const checkout_session_id = useQueryParam("checkout_session_id");
  const { churchId } = useParams();
  const [donerName, setDonerName] = useState("");

  useEffect(() => {
    if (checkout_session_id) {
      getTransaction(churchId, checkout_session_id)
        .then(({ data }) => {
          setLoading(false);
          if (data) {
            setReceipt(data);
          } else {
            navigate("/churches");
            toast.error("Failed to Receipt. Please try again later");
          }
        })
        .catch(() => {
          setLoading(false);
          navigate("/churches");
          toast.error("Failed to Receipt. Please try again later");
        });
    }
  }, [churchId, checkout_session_id]);

  const AddDoner = async () => {
    let result = await sendDonerName({
      donatorName: donerName,
      churchId: churchId,
    });
    if (result.data) {
      navigate("/churches");
    }
  };

  return (
    <div>
      {isLoading && (receipt === undefined || receipt === null) && (
        <div className="d-flex justify-content-center mt-2 mb-2">
          <ClipLoader loading size={50} />
        </div>
      )}
      {!isLoading && receipt !== undefined && receipt !== null && (
        <div className={s.receiptbox}>
          <div
            className="d-flex justify-content-between"
            style={{ top: "1rem" }}
          >
            <div className={s.page}>
              <div className={s.subTitlePage}>Thank you for Donation</div>
              <div className={s.receiptText}>
                TransactionId : {receipt?.session.payment_intent}
              </div>
              <div className={s.receiptText}>
                Customer Email : {receipt?.session.customer_details.email}
              </div>
              <div className={s.receiptText}>
                Customer Payment type :{" "}
                {receipt?.session.payment_method_types[0]}
              </div>
              <div className={s.receiptText}>
                Customer Payment status : {receipt?.session.payment_status}
              </div>
              <div className={s.receiptText}>
                Church Name : {receipt?.church.name}
              </div>
              <div className={s.receiptText}>
                Church Address :{" "}
                {receipt?.church.address && `${receipt?.church.address}, `}{" "}
                {receipt?.church.phone && `${receipt?.church.phone}, `}{" "}
                {receipt?.church.email}
              </div>
              {receipt?.church.description && (
                <div className={s.receiptText}>
                  {receipt?.church.description}
                </div>
              )}

              <div
                className={"d-flex justify-content-center " + s.backtochurch}
              >
                <button
                  onClick={() => navigate(`/churches/${churchId}`)}
                  className={s.backtochurchbutton}
                >
                  Back to Church
                </button>
              </div>
              <div className={s.receiptText + " text mt-2"}>
                <p>
                  Would you like to have your name displayed as a donor at our
                  church?
                </p>
              </div>
              <div
                className={"text d-flex justify-content-around " + s.donerName}
              >
                <input
                  placeholder="Type your name here"
                  onChange={(e) => setDonerName(e.target.value)}
                  value={donerName}
                  className={s.NameBox}
                />
                <Button onClick={AddDoner} disabled={!donerName}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div className={s.receiptbox}>
        <div className="d-flex justify-content-between" style={{ top: "1rem" }}>
          <div className={s.page}>
            <div className={s.subTitlePage}>Thank you for Donation</div>
            <div className={s.receiptText}>
              TransactionId : {receipt?.session.payment_intent}
            </div>
            <div className={s.receiptText}>
              Customer Email : {receipt?.session.customer_details.email}
            </div>
            <div className={s.receiptText}>
              Customer Payment type : {receipt?.session.payment_method_types[0]}
            </div>
            <div className={s.receiptText}>
              Customer Payment status : {receipt?.session.payment_status}
            </div>
            <div className={s.receiptText}>
              Church Name : {receipt?.church.name}
            </div>
            <div className={s.receiptText}>
              Church Address :{" "}
              {receipt?.church.address && `${receipt?.church.address}, `}{" "}
              {receipt?.church.phone && `${receipt?.church.phone}, `}{" "}
              {receipt?.church.email}
            </div>
            {receipt?.church.description && (
              <div className={s.receiptText}>{receipt?.church.description}</div>
            )}

            <div className={"d-flex justify-content-center " + s.backtochurch}>
              <button
                onClick={() => navigate(`/churches/${churchId}`)}
                className={s.backtochurchbutton}
              >
                Back to Church
              </button>
            </div>
            <div className={s.receiptText + " text mt-2"}>
              <p>
                Would you like to have your name displayed as a donor at our
                church?
              </p>
            </div>
            <div
              className={"text d-flex justify-content-around " + s.donerName}
            >
              <input
                placeholder="Type your name here"
                onChange={(e) => setDonerName(e.target.value)}
                value={donerName}
                className={s.NameBox}
              />
              <Button onClick={AddDoner} disabled={!donerName}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ReceiptPage;
