import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "@reach/router";
import ClipLoader from "react-spinners/ClipLoader";
import { navigate } from "gatsby-link";
import { loadStripe } from "@stripe/stripe-js";
import { useStaticQuery, graphql } from "gatsby";
import { useQueryParam } from "gatsby-query-params";
import Modal from "react-bootstrap-modal";
import { getChurch, getDonatorList, stripeCheckout } from "../../api/churches";
import Button from "../Shared/Button";
import Input from "../Shared/Input";
import { useSizes } from "../../utils/sizes";
import Img from "gatsby-image";
import facebookImage2 from "../../images/facebook2.svg";
import twitterImage from "../../images/twitter.svg";
import websiteImage from "../../images/website.png";

import s from "./ChurchPage.module.css";
import "react-bootstrap-modal/lib/css/rbm-complete.css";
import moment from "moment";

const ChurchPage = () => {
  const [church, setChurch] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [donateModalOpen, setDonateModalOpen] = useState(false);
  const [donateAmount, setDonateAmount] = useState("");
  const [donatorList, setDonatorList] = useState([]);

  const [width] = useSizes();
  const { churchId } = useParams();
  const success = useQueryParam("checkout_session_id");
  const data = useStaticQuery(graphql`
    query {
      selectedCandleIcon: file(relativePath: { eq: "tear-icon.svg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
      }
      aboutUsImage: file(relativePath: { eq: "about-img.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const [board, setBoard] = useState([
    Array.from({ length: 16 }).map(() => "EMPTY"),
    Array.from({ length: 15 }).map(() => "EMPTY"),
  ]);

  const candlesCount = useMemo(
    () =>
      board.reduce(
        (result, candles) =>
          result + candles.filter((candle) => candle === "SELECTED").length,
        0
      ),
    [board]
  );

  const totalPrice =
    candlesCount * (church ? church.price : 0) +
    (donateAmount ? +donateAmount : 0);

  const handleCandleClick = (i, j, candle) => {
    if (board[i][j] === "BUSY") return;
    if (candle === "SELECTED" && candlesCount >= 5) {
      toast.warning("You can select only 5 candles");
      return;
    }
    setBoard((prevBoard) => [
      ...prevBoard.filter((_, k) => k < i),
      [
        ...prevBoard[i].filter((_, k) => k < j),
        candle,
        ...prevBoard[i].filter((_, k) => k > j),
      ],
      ...prevBoard.filter((_, k) => k > i),
    ]);
  };

  const formatPrice = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return formatter.format(amount);
  };

  const checkout = async () => {
    setIsCheckoutLoading(true);
    const candleIds = board.reduce(
      (result, row, rowIndex) => [
        ...result,
        ...row
          .map((_, i) => i)
          .filter((i) => row[i] === "SELECTED")
          .map(
            (i) => i + (rowIndex !== 0 ? board[rowIndex - 1].length : 0) + 1
          ),
      ],
      []
    );
    // pk_test_VdfEvhaDGI3ir9KV8LzUyWZI for test mode
    // pk_live_AuPkYDkZe6nUIQqEqM4Hctf0 for live mode
    //todo enable stripe buttonxz
    const stripe = await loadStripe("pk_live_AuPkYDkZe6nUIQqEqM4Hctf0");
    // const stripe = await loadStripe('pk_live_AuPkYDkZe6nUIQqEqM4Hctf0', {
    //   stripeAccount: church.stripeAccountId
    // });
    const { data } = await stripeCheckout(churchId, candleIds, donateAmount);
    const result = await stripe.redirectToCheckout({
      sessionId: data.id,
    });
    if (result.error) {
      toast.error("Oops. Something went wrong. Please try again later");
    }
  };

  useEffect(() => {
    getChurch(churchId)
      .then(({ data }) => {
        if (!data.enableCandles || !data.payoutsEnabled) {
          setLoading(false);
          return navigate("/churches");
        } else {
          setChurch(data);
          let newBoard = [];
          if (churchId == 1) {
            newBoard = [
              Array.from({ length: 9 }).map(() => "EMPTY"),
              Array.from({ length: 11 }).map(() => "EMPTY"),
              Array.from({ length: 10 }).map(() => "EMPTY"),
            ];
          } else {
            newBoard = [
              Array.from({ length: 16 }).map(() => "EMPTY"),
              Array.from({ length: 15 }).map(() => "EMPTY"),
            ];
          }
          // const newBoard = [Array.from({ length: 16 }).map(() => 'EMPTY'),
          // Array.from({ length: 15 }).map(() => 'EMPTY')];

          newBoard.forEach((row, i) => {
            row.forEach((_, j) => {
              const slot =
                j + 1 + (newBoard[i - 1] ? newBoard[i - 1].length : 0);
              if (data.busyCandles.includes(slot)) {
                row[j] = "BUSY";
              }
            });
          });
          setBoard(newBoard);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        navigate("/churches");
        toast.error("Failed to load church. Please try again later");
      });
    // .finally(() => setLoading(false));
    getDonatorList(churchId)
      .then((res) => {
        let h24 = res.data.filter((item) => {
          let createDate = Math.round(new Date(item.createdAt).getTime());
          let lastDate = Math.round(new Date().getTime()) - 1000 * 60 * 60 * 24;
          return createDate > lastDate;
        });
        console.log(res);
        console.log(h24);
        setDonatorList(h24);
      })
      .catch((err) => console.log("something error in get donator", err));
  }, [churchId]);

  useEffect(() => {
    if (success) {
      toast.success("Thanks you!!!");
      // navigate(`/churches/${churchId}`);
    }
  }, [success]);

  return (
    <div>
      {isLoading && (church === undefined || church === null) && (
        <div className="position-relative church-box">
          <div className="d-flex justify-content-center mt-2 mb-2">
            <ClipLoader loading size={50} />
          </div>
        </div>
      )}
      {!isLoading && church !== undefined && church !== null && (
        <div className="position-relative church-box">
          <img
            style={{ marginTop: 20, width: "100%" }}
            className="bg-img"
            src={
              width < 600
                ? church.verticalChurchBackgroundUrl
                : church.horizontalChurchBackgroundUrl
            }
          />
          <div className="">
            <div className="d-flex justify-content-between col2row">
              <div>
                <div className="secondarySubTitle">{church.name}</div>
                <div className="text">
                  {church.address && `${church.address}, `}{" "}
                  {church.phone && `${church.phone}, `} {church.email}
                </div>
                {church.description && (
                  <div className="text">{church.description}</div>
                )}
              </div>
              <div>
                <Button onClick={() => setDonateModalOpen(true)}>
                  Donate Church
                </Button>
              </div>
            </div>
            <div className={s.label}>
              <div>Select your candle location</div>
              <div className="font-weight-bold ml-auto">
                {formatPrice(totalPrice)}
              </div>
            </div>
            <div className={s.candlesPanel}>
              {board.map((candles, i) => (
                <div className={s.candlesList}>
                  {candles.map((candle, j) => (
                    <div
                      className={s.candle}
                      onClick={() =>
                        handleCandleClick(
                          i,
                          j,
                          candle === "EMPTY" ? "SELECTED" : "EMPTY"
                        )
                      }
                    >
                      {candle === "SELECTED" && (
                        <img src={data.selectedCandleIcon.publicURL} />
                      )}
                      {candle === "BUSY" && `🕯`}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className={s.buttonsWrapper}>
              <div className={"mt-auto text " + s.textSize}>
                Candle duration: {church.time} h
              </div>
              <Button
                className={s.checkoutButton}
                onClick={checkout}
                isLoading={isCheckoutLoading}
                disabled={!totalPrice}
              >
                PROCEED TO CHECKOUT
              </Button>
            </div>
            <div className={s.noticeText}>
              All payments go directly to the Church.
            </div>
            <div className={s.socialMediaLink + " mt-2"}>
              <div className={s.socialMediaLink}>
                <a
                  href={
                    church.facebookUrl == null
                      ? "https://www.facebook.com/"
                      : church.facebookUrl
                  }
                  target="_blank"
                >
                  <img src={facebookImage2} className={s.socialMediaImage} />
                </a>
                <a
                  href={
                    church.twitterUrl == null
                      ? "https://www.twitter.com/"
                      : church.twitterUrl
                  }
                  target="_blank"
                >
                  <img src={twitterImage} className={s.socialMediaImage} />
                </a>
              </div>
              <div>
                <a
                  href={church.websiteUrl == null ? "/" : church.websiteUrl}
                  target="_blank"
                >
                  <img src={websiteImage} className={s.socialMediaImage} />
                </a>
              </div>
            </div>
            <div className={s.donoters}>
              <h3>Recent Donators name</h3>
              {donatorList.map((item) => (
                <div key={item.id}>{item.donator_name}</div>
              ))}
            </div>
          </div>
          {donateModalOpen && (
            <DonateModal
              donateAmount={donateAmount}
              onHide={() => setDonateModalOpen(false)}
              saveDonateAmount={setDonateAmount}
            />
          )}
        </div>
      )}
      {!isLoading && church !== undefined && church !== null && (
        <div className={"container home " + s.aboutUsContainer}>
          <a id="about" name="about">
            <div className="d-flex flex-wrap-mobile">
              <div className={s.aboutUs}>
                <h2 className={s.subTitle}>About Us</h2>
                <p className="text">
                  Light Safe Candle is dedicated to serve the community of
                  churches and church service with improving safety and
                  finances. This is the ultimate solution to help churches
                  improve safety from fire and other damages related to open
                  fire candle station on the building by providing safe low
                  voltage LED light bold candles.Our team has developed unique
                  Patented software and hardware to provide spiritual service by
                  allowing a user to light a candle in any selected church
                  directly from the smartphone or browser with additional option
                  display of desired text and photo to be displayed in the
                  church. Engage more people ONLINE to help donate and support
                  the church. Keeping People safe due to pandemic. Maintenance
                  Free Light Safe Candle service is reusable candle station with
                  24/7 tech support. Lower insurance fee eliminating open fire
                  threat.
                </p>
              </div>
              {data.aboutUsImage.childImageSharp.fluid && (
                <div className={s.aboutUsImageWrapper}>
                  <Img
                    className={s.aboutUsImage}
                    fluid={data.aboutUsImage.childImageSharp.fluid}
                  />
                  <div className={s.aboutUsImagePlaceholder} />
                </div>
              )}
            </div>
          </a>
        </div>
      )}
    </div>
  );
};

const DonateModal = ({ donateAmount, onHide, saveDonateAmount }) => {
  const [donateAmountValue, setDonateAmountValue] = useState(donateAmount);

  const saveDonate = () => {
    saveDonateAmount(donateAmountValue);
    onHide();
  };

  return (
    <Modal show onHide={onHide} aria-labelledby="ModalHeader">
      <Modal.Header closeButton>
        <Modal.Title id="ModalHeader">Select Donate Amount</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          id="donateAmount"
          type="number"
          label="Donate Amount"
          placeholder="Amount"
          value={donateAmountValue}
          onChange={(event) => setDonateAmountValue(event.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={saveDonate}>Enter Donate Amount</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChurchPage;
