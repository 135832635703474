import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ChurchesPage from "../components/ChurchesPage";
import ChurchPage from "../components/ChurchPage";
import ReceiptPage from "../components/ReceiptPage";

const Churches = () => {
  return (
    <Layout>
      <SEO title="List of Churches" />
      <Router basepath="/churches">
        <ChurchesPage path="/" />
        <ChurchPage path="/:churchId" />
        <ReceiptPage path="/:churchId/order/success" />
      </Router>
    </Layout>
  );
};

export default Churches;
