import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import FlexSearch from 'flexsearch';
import { getChurches } from '../../api/churches';
import { useSizes } from '../../utils/sizes';
import ChurchesList from '../ChurchesList';
import ChurchesMap from '../ChurchesMap';
import Button from '../Shared/Button';

import s from './ChurchesPage.module.css';

const index = FlexSearch.create({
    encode: 'balance',
    tokenize: 'forward',
    threshold: 0,
    async: false,
    worker: false,
    cache: false
});


const ChurchesPage = () => {
    const [width, height] = useSizes();
    const [churches, setChurches] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [query, setQuery] = useState('');
    const [order, setOrder] = useState('asc');
    const [status, setStatus] = useState('all');
    const [view, setView] = useState('list');
    useEffect(() => {
        setLoading(true);
        getChurches()
            .then(({ data }) => {
                const churches = data;
                churches.forEach((church, i) => {
                    index.add(i, church.name);
                });
                setChurches(churches);
            })
            .catch(() => toast.error('Failed to load churhces. Please try again later'))
            .finally(() => setLoading(false))
    }, []);
    const churchesToDisplay = useMemo(() => {
        let result = churches;
        if (query) {
            const idxs = index.search(query);
            result = idxs.map(idx => churches[idx]);
        }
        if(status=='active'){
            result=result.filter(function (item){
                return item.status==='active';
            });
        }
        return [...result].sort((a, b) => {
            if (order === 'asc') {
                return a.name.localeCompare(b.name);
            }
            else {
                return b.name.localeCompare(a.name);
            }
        });
    }, [churches, query, order, status]);
    return (
        <div className={s.layout}>
            <div className='container'>
                <h1 className={classNames(s.title, 'title')}>Find A Church</h1>
            </div>
            <div className='container'>
                {width <= 1000 && (
                    <Button
                        className='d-block w-100'
                        onClick={() => setView(prevView => prevView === 'map' ? 'list' : 'map')}
                    >
                        {view === 'list' ? 'Go to Map' : 'Go to List'}
                    </Button>
                )}
            </div>
            <div className={s.border} />
            <div className='container map'>
                <div className='d-flex'>
                    {((width > 1000) || (view === 'map')) && (
                        <ChurchesMap
                            className={s.map}
                            churches={churchesToDisplay}
                        />
                    )}
                    {((width > 1000) || (view === 'list')) && (
                        <ChurchesList
                            className={s.churchesList}
                            churches={churchesToDisplay}
                            query={query}
                            setQuery={setQuery}
                            isLoading={isLoading}
                            order={order}
                            setOrder={setOrder}
                            status={status}
                            setStatus={setStatus}
                        />)}
                </div>
            </div>
            <div className={s.border} />
        </div>
    );
};

export default ChurchesPage;
