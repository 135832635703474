import React from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import ClipLoader from "react-spinners/ClipLoader";
import Button from "../Shared/Button";
import Input from "../Shared/Input";
import classNames from "classnames";

import "./ChurchesList.css";

const ChurchesList = ({
  churches,
  isLoading,
  className,
  query,
  setQuery,
  order,
  setOrder,
  status,
  setStatus,
}) => {
  const data = useStaticQuery(graphql`
    query {
      searchIcon: file(relativePath: { eq: "search-icon.svg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
      }
    }
  `);

  return (
    <div className={className}>
      <div className="control">
        {/* <div className='d-flex search-form align-items-center justify-space-between'> */}
        <div className="d-flex search-form justify-space-between">
          <div className="controlGroup">
            <div className="radioWrapper">
              <label
                className={
                  order == "asc"
                    ? classNames("background d-block rbtn")
                    : classNames("d-block rbtn")
                }
                htmlFor="asc"
              >
                A to Z
              </label>
              <input
                id="asc"
                type="radio"
                value="asc"
                name="order"
                checked={order === "asc"}
                onChange={() => setOrder("asc")}
                className="labelInput"
              />
            </div>
            <div className="radioWrapper">
              <label
                className={
                  order == "desc"
                    ? classNames("background d-block rbtn")
                    : classNames("d-block rbtn")
                }
                htmlFor="desc"
              >
                Z to A
              </label>
              <input
                id="desc"
                type="radio"
                value="desc"
                name="order"
                checked={order === "desc"}
                onChange={() => setOrder("desc")}
                className="labelInput"
              />
            </div>
            <div className="radioWrapper">
              <label
                className={
                  status == "active"
                    ? classNames(" d-block background rbtn")
                    : classNames("d-block rbtn")
                }
                // className={"d-block margin-left " + s.rbtn}
                htmlFor="active"
              >
                Active
              </label>
              <input
                id="active"
                type="radio"
                value="active"
                name="status"
                className="margin-left"
                checked={status === "active"}
                onChange={() => setStatus("active")}
                className="labelInput"
              />
            </div>
            <div className="radioWrapper">
              <label
                className={
                  status == "all"
                    ? classNames("background d-block rbtn")
                    : classNames("d-block rbtn")
                }
                // className={"d-block margin-left " + s.rbtn}
                htmlFor="all"
              >
                All
              </label>
              <input
                id="all"
                type="radio"
                value="all"
                className="margin-left"
                name="status"
                checked={status === "all"}
                onChange={() => setStatus("all")}
                className="labelInput"
              />
            </div>
          </div>
          <div className="controlGroup">
            <Input
              groupClassName="mb-0 "
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              iconUrl={data.searchIcon.publicURL}
              className="inputBox"
              placeholder="Search Church Here"
            />
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="d-flex justify-content-center mt-2 mb-2">
          <ClipLoader loading size={50} />
        </div>
      )}
      <div>
        {churches.map((church, i) => (
          <div className="card" key={i}>
            <div className="secondarySubTitle">{church.name}</div>
            {church.address && <span className="text">{church.address}, </span>}
            <span className="text">{church.email}, </span>
            <span className="text">{church.phone}</span>
            {church.description && (
              <div className="text">{church.description}</div>
            )}
            <div className="button">
              {church.enableCandles ? (
                <Button
                  variant="secondary"
                  onClick={() => navigate(`/churches/${church.id}`)}
                >
                  LIGHT A CANDLE
                </Button>
              ) : (
                <div className="text">Coming soon</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChurchesList;
