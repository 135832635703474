import React from "react";
import classNames from "classnames";

import s from "./Input.module.css";

const Input = ({
  name,
  id,
  label,
  type,
  placeholder,
  isInvalid,
  groupClassName,
  iconUrl,
  ...rest
}) => {
  const className = classNames(s.input, {
    [s.invalid]: isInvalid,
  });
  const controlsProps = {
    ...rest,
    id,
    type,
    name,
    className,
    placeholder,
  };

  return (
    <div className={classNames(s.formGroup, groupClassName)}>
      <label className={s.label} htmlFor={id}>
        {label}
      </label>
      {type === "textarea" ? (
        <textarea {...controlsProps} />
      ) : (
        <input {...controlsProps} />
      )}
      {iconUrl && <img src={iconUrl} className={s.icon} />}
    </div>
  );
};

Input.defaultProps = {
  isInvalid: false,
};

export default Input;
