import React, { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { LoadScriptNext, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { useStaticQuery, graphql } from 'gatsby';

import s from './ChurchesMap.module.css';


const apiKey = 'AIzaSyBlFAWszegTGQRcytyDMYH1p9ywUZMvYWs';

const containerStyle = {
    width: '100%',
    height: 'calc(100vh - 80px)',
    boxShadow: '0 0.5rem 2rem #d8dfed'
};

const center = {
    lat: 39,
    lng: -98
};

const libraries = ['places'];

const ChurchesMap = ({ churches, className }) => {
    const data = useStaticQuery(graphql`
    query {
      markerIcon: file(relativePath: { eq: "map-marker.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
      }
        }
    `);

    return (

        <LoadScriptNext
            googleMapsApiKey={apiKey}
            libraries={libraries}
            loadingElement={
                <div className={s.spinnerWrapper}>
                    <ClipLoader loading size={100} />
                </div>
            }
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                zoom={5}
                center={center}
            >
                {churches.map((church, i) => (
                    <ChurchMarker
                        position={{ lat: church.lat, lng: church.lng }}
                        key={i}
                        icon={data.markerIcon.publicURL}
                        church={church}
                    />
                ))}
            </GoogleMap>
        </LoadScriptNext>

    );
};

const ChurchMarker = ({ position, icon, church }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Marker
                position={position}
                icon={icon}
                onClick={() => setOpen(prev => !prev)}
                title={church.name}
            />
            {open && (
                <InfoWindow
                    position={position}
                    onCloseClick={() => setOpen(prev => !prev)}
                >
                    <div className={s.infoWindowContent}>
                        <div>
                            <span className={s.popupLabel}>Name:</span>{church.name}
                        </div>
                        {church.description && (
                            <div>
                                <span className={s.popupLabel}>Info:</span>{church.description}
                            </div>
                        )}
                        {church.address && (
                            <div>
                                <span className={s.popupLabel}>Address:</span>{church.address}
                            </div>
                        )}
                    </div>
                </InfoWindow>
            )}
        </>
    )
};

export default ChurchesMap